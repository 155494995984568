import * as firebase from "firebase/app";
import * as React from "react";
import styled from "styled-components";
import {
  CardElement,
  injectStripe,
  ReactStripeElements
} from "react-stripe-elements";

interface State {
  isLoading: boolean;
}

class Payment extends React.Component<
  ReactStripeElements.InjectedStripeProps,
  State
> {
  state: State = {
    isLoading: false
  };

  public render() {
    const { isLoading } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <CardElement />
        <button disabled={isLoading}>Pay</button>
      </Form>
    );
  }

  private handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.subscribe();
    } catch (error) {
      this.setState({ isLoading: false });
      alert(error.message);
    }
  };

  private async subscribe() {
    const { stripe } = this.props;

    if (stripe) {
      const result = await stripe.createToken();
      if (result.error) {
        throw new Error(result.error.message);
      } else if (result.token) {
        const subscribe = firebase.functions().httpsCallable("subscribe");
        await subscribe({ source: result.token.id });
      }
    }
  }
}

const Form = styled.form`
  max-width: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default injectStripe(Payment);
