import * as firebase from "firebase/app";

export default function() {
  const config = {
    apiKey: "AIzaSyCmm9CYeQeagdHB-WsnIHabtb1tiH0CY6c",
    authDomain: "platoon-fit.firebaseapp.com",
    databaseURL: "https://platoon-fit.firebaseio.com",
    projectId: "platoon-fit",
    storageBucket: "platoon-fit.appspot.com",
    messagingSenderId: "871299280374",
    appId: "1:871299280374:web:7bb59fc678a905b958ebd7",
  };

  firebase.initializeApp(config);

  const firestore = firebase.firestore();
  const settings = { timestampsInSnapshots: true };
  firestore.settings(settings);
}
