import * as firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import * as React from "react";
import Payment from "./Payment";
import styled from "styled-components";
import { Elements, StripeProvider } from "react-stripe-elements";
import { StyledFirebaseAuth } from "react-firebaseui";
import "./App.css";
import "firebase/functions";
import "firebase/firestore";

interface State {
  user?: firebase.User | null;
  status?: string;
}

class App extends React.Component<{}, State> {
  state: State = {};

  unregisterAuthObserver?: firebase.Unsubscribe;
  unregisterUserObserver?: () => void;

  uiConfig: firebaseui.auth.Config = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    },
    credentialHelper: firebaseui.auth.CredentialHelper.NONE
  };

  public componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setState({ user }));
  }

  public componentDidUpdate(prevProps: {}, prevState: State) {
    const { user } = this.state;

    if (user && !prevState.user) {
      this.unregisterUserObserver = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .onSnapshot(snapshot =>
          this.setState({ status: snapshot.get("stripeStatus") })
        );
    } else if (!user && prevState.user) {
      this.unregisterUserObserver!();
    }
  }

  componentWillUnmount() {
    this.unregisterAuthObserver!();
  }

  public render() {
    return <Container>{this.renderContent()}</Container>;
  }

  private renderContent() {
    const { user, status } = this.state;

    return user
      ? status === "active"
        ? this.renderProfile()
        : this.renderPayment()
      : user === null
      ? this.renderAuth()
      : this.renderLoading();
  }

  private renderAuth() {
    return (
      <StyledFirebaseAuth
        uiConfig={this.uiConfig}
        firebaseAuth={firebase.auth()}
      />
    );
  }

  private renderPayment() {
    return (
      <StripeProvider apiKey="pk_test_meeBDtQNCdpa3guztDWtcb8O">
        <Elements>
          <Payment />
        </Elements>
      </StripeProvider>
    );
  }

  private renderProfile() {
    return (
      <Columnn>
        <p>Welcome {firebase.auth().currentUser!.displayName}!</p>
        <button onClick={this.signOut}>Sign out</button>
      </Columnn>
    );
  }

  private signOut = () => firebase.auth().signOut();

  private renderLoading() {
    return null;
  }
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default App;
